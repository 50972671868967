// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import VueRouter from 'vue-router'
import BootstrapVue from 'bootstrap-vue';
import Mitt from 'mitt';
import { createPinia, PiniaVuePlugin } from 'pinia';

import App from '@/App.vue';
import Router from '@/router/router.js';
import { Auth } from '@/auth.js';

Vue.use( VueRouter );
Vue.use( BootstrapVue );
Vue.use( PiniaVuePlugin );

const pinia = createPinia();
const emitter = Mitt();

Vue.prototype.$emitter = emitter;
Vue.prototype.$auth = new Auth( emitter );

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router: Router,
    pinia,
    template: '<App/>',
    components: {
        App
    }
});
